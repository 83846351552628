var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Add Training Course "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Training Course Name","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Course Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"training-course-name"},model:{value:(_vm.addCourseForm.name),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "name", $$v)},expression:"addCourseForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"In-Link Name","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"In-Link Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"training-course-link"},on:{"input":function($event){return _vm.removeSpace($event)},"change":_vm.checkLink},model:{value:(_vm.addCourseForm.link_id),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "link_id", $$v)},expression:"addCourseForm.link_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(!_vm.validLink)?_c('small',{staticClass:"text-danger"},[_vm._v("Not Valid")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"YouTube Link","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"YouTube Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"course-youtube-link"},model:{value:(_vm.addCourseForm.video_link),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "video_link", $$v)},expression:"addCourseForm.video_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Duration [Days]","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Duration [Days]"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"data-testid":"course-duration","type":"number"},model:{value:(_vm.addCourseForm.duration),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "duration", $$v)},expression:"addCourseForm.duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v("Is Free Course")]),_c('b-form-radio-group',{staticClass:"mb-2",attrs:{"label":"saveas"},model:{value:(_vm.addCourseForm.is_free),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "is_free", $$v)},expression:"addCourseForm.is_free"}},[_c('b-form-radio',{attrs:{"value":"1"}},[_vm._v(" Yes ")]),_c('b-form-radio',{attrs:{"value":"0"}},[_vm._v(" No ")])],1)],1),(_vm.addCourseForm.is_free == 0)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Price","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Duration [Days]"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","type":"number"},model:{value:(_vm.addCourseForm.price),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "price", $$v)},expression:"addCourseForm.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2428600993)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Tab image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewE2",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewE2.src = _vm.Tabimage
                          _vm.addCourseForm.tab_image=''}}},[_vm._v(" Remove Image ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tab Image ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Tab Image "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputE2",attrs:{"id":"course-tab-image","accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRendererTab},model:{value:(_vm.addCourseForm.tab_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "tab_image", $$v)},expression:"addCourseForm.tab_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tab Image Alt Text","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":"alt-image"},model:{value:(_vm.addCourseForm.alt_tab_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "alt_tab_image", $$v)},expression:"addCourseForm.alt_tab_image"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Header image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewEl.src = _vm.Tabimage
                          _vm.addCourseForm.header_image=''}}},[_vm._v(" Remove Image ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Header Image ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Header Image "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",attrs:{"data-testid":"course-header-image","accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.addCourseForm.header_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "header_image", $$v)},expression:"addCourseForm.header_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Header image Alt Text","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":" alt_header_image"},model:{value:(_vm.addCourseForm.alt_header_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "alt_header_image", $$v)},expression:"addCourseForm.alt_header_image"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Og Graph Image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewE3",staticClass:"rounded mr-2 mb-1 mb-md-0 col-12",attrs:{"src":_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewE3.src = _vm.Tabimage
                          _vm.addCourseForm.og_image=''}}},[_vm._v(" Remove Image ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block  mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Og Graph Image","label-for":"blog-tab-title"}},[_c('validation-provider',{attrs:{"name":"Tab Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputE3",attrs:{"data-testid":"course-og-image","accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRendererTab3},model:{value:(_vm.addCourseForm.og_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "og_image", $$v)},expression:"addCourseForm.og_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tab Image Alt Text","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":"alt-image"},model:{value:(_vm.addCourseForm.alt_og_image),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "alt_og_image", $$v)},expression:"addCourseForm.alt_og_image"}})],1)],1)],1)],1)],1)]),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[(!_vm.isLoading)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"data-testid":"course-submit-button","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")]):_vm._e(),(_vm.isLoading)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":""}},[_vm._v(" Loading... "),_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"sr-only"})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }